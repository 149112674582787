import React, { useState, useEffect } from "react";
import Metrics from "../metrics/Metrics";
import Search from "../Search/Search";
import ShipmentTable from "../shipmentTable/ShipmentTable";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { Box } from "@mui/material";
import { fetchShipments, getCachedisCustomerUser } from "../../api/shipmentTable";
import AddCustomerPopup from "../../components/AddCustomerPopup";
import CustomerShipmentTable from "../CustomerShipmentTable/CustomerShipmentTable";
import { convertUTCTimeToLocalTime } from "../../utilities/shipmentTable";

const DashboardOverview = ({ accountInfo, onThemeChange, onLogout }) => {
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [exportPopupOpen, setExportPopupOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  const [open, setOpen] = React.useState(false); // state to toggle opening and closing of add customer popup
  const [isSaveBtnClicked, setIsSaveBtnClicked] = useState(false)

  const isCustomerUser = getCachedisCustomerUser();

  const handleApplyFilters = (filters, isClearingFilters = false) => {
    if (isClearingFilters) {
      // If clearing filters, update the appliedFilters state with the provided filters
      setAppliedFilters(filters);
    } else {
      // If applying filters, merge the provided filters with the existing appliedFilters state
      setAppliedFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }
    setAppliedFiltersCount(filters.AppliedFiltersCount || 0);
  };

  const handleExportButtonClick = () => {
    setExportPopupOpen(true);
  };

  const handleExportPopupClose = (format) => {
    setExportPopupOpen(false);
    if (format) {
      exportData(format);
    }
  };

  const formatDateTime = (dateTimeString) => {
    if (dateTimeString) {
      const formattedDateTime = moment(dateTimeString, "YYYYMMDDHHmmss").format("MM-DD-YY HH:mm");
      return formattedDateTime !== "Invalid date" ? formattedDateTime : "";
    }
    return "";
  };

  const formatDate = (dateString) => {
    if (dateString) {
      const formattedDate = moment(dateString, "YYYYMMDDHHmmss").format("MM-DD-YY");
      return formattedDate !== "Invalid date" ? formattedDate : "";
    }
    return "";
  };

  const formatDeliveryStatus = (status) => {
    if (!status) return "";
    const statusMap = {
      "Intransit": "In Transit",
    };
    return statusMap[status] || status;
  };

  const handleRowsChange = (newRows) => {
    console.log("Rows data:", newRows);
    setRows(newRows);
  };

  const handleFetchError = (hasError) => {
    setIsExportDisabled(hasError);
  };

  const formatOriginalRequestDate = (dateString) => {
    if (dateString) {
      let formattedDate;
      // Try parsing as "DD-MMM-YYYY"
      formattedDate = moment(dateString, "DD-MMM-YYYY", true);

      // If not valid, try parsing as "YYYY-MM-DD"
      if (!formattedDate.isValid()) {
        formattedDate = moment(dateString, "YYYY-MM-DD", true);
      }

      // If still not valid, return empty string
      if (!formattedDate.isValid()) {
        return "";
      }

      // Format the date as "MM-DD-YY"
      return formattedDate.format("MM-DD-YY");
    }
    return "";
  };

  const exportData = async (format) => {
    try {
      setIsExportDisabled(true); // Disable export button while fetching

      const payload = {
        ...appliedFilters,
        SortBy: "DeliveryAppointment",
        SortOrder: "descending",
      };

      const response = await fetchShipments(payload);
      const formattedData = response.shipments.map((row) => {
        if (isCustomerUser) {
          // Customer user specific fields
          return {
            "Delivery No.": row.DeliveryNumber,
            "PO Number": row.ShipmentDetails.PONumber,
            "Customer Region": row.ShipmentDetails.CustomerRegion,
            "City": row.City,
            "State": row.State,
            "Delivery Appointment": formatDateTime(row.DeliveryAppointment),
            "Delivery Status": formatDeliveryStatus(row.DeliveryStatus),
            "Delivery ETA": formatDateTime(row.DeliveryEta),
            "Customer": row.CustomerName,
            "Notes": row.ShipmentDetails?.Notes || "",
            "Reschedule Date": formatDateTime(row.ShipmentDetails?.RescheduleDate),
            "Warehouse": row.WareHouseNbr
          };
        } else {
          return {
            "Delivery No.": row.DeliveryNumber,
            "PO Number": row.ShipmentDetails.PONumber,
            "Delivery Appointment": formatDateTime(row.DeliveryAppointment),
            "Ship Date": formatDateTime(row.ShipmentDetails?.ShipDate),
            "Source" :row.ShipmentDetails.OrgName,
            "City": row.City,
            "Delivery Status": formatDeliveryStatus(row.DeliveryStatus),
            "Carrier": row.ShipmentDetails.Carrier,
            "NB Number": row.NBNumber,
            "Preload": row.ShipmentDetails.Preload,
            "Transit Time": row.ShipmentDetails.TransitTime,
            "Distance": row.ShipmentDetails.Distance,
            "Pick-up ETA": formatDateTime(row.ShipmentDetails.PickupEta),
            "Pick-up Status": row.PickupStatus,
            "State": row.State,
            "Pick-up Appointment": formatDateTime(row.ShipmentDetails.PickupAppointment),
            "Delivery ETA": formatDateTime(row.DeliveryEta),
            "Delivery Time": formatDateTime(row.DeliveryTime),
            "Reason Code": row.ShipmentDetails.ReasonCode,
            "Tracker": row?.UpdatedBy || row.ShipmentDetails?.UpdatedBy || row.ShipmentDetails?.CreatedBy,
            "Last Update Time": convertUTCTimeToLocalTime(row.UpdatedTime),
            "Pick-up Time": formatDateTime(row.ShipmentDetails.PickupTime),
            "Notes": row.Notes,
            "Warehouse": row.WareHouseNbr,
            "Customer": row.CustomerName,
            "Transport Mode": row.ShipmentDetails.TransportMode,
            "Customer Region": row.ShipmentDetails.CustomerRegion,
            "Original Request Date": formatOriginalRequestDate( row.OriginalRequestDate || row.ShipmentDetails?.OriginalRequestDate),
            "Customer ID": row.DestinationLocationGID,
            "Data Source": row.DataSource,
            "Phone Number": row.ContactDetails,
            "Commodity": row.ShipmentDetails.Commodity,
            "Reschedule Date": formatDateTime(row.ShipmentDetails.RescheduleDate),
          };
        }
      });
      if (format === "xlsx") {
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        // Adjust column widths
        const columnWidths = [
          { wch: 20 }, // Delivery Number
          { wch: 25 }, // PO Number
          { wch: 20 }, // Delivery Appointment
          { wch: 15 }, // Ship Date
          { wch: 25 }, // Source
          { wch: 20 }, // City
          { wch: 20 }, // Delivery Status
          { wch: 15 }, // Carrier
          { wch: 15 }, // NB Number
          { wch: 15 }, // Preload
          { wch: 15 }, // Transit Time
          { wch: 15 }, // Distance
          { wch: 20 }, // Pickup ETA
          { wch: 20 }, // Pickup Status
          { wch: 10 }, // State
          { wch: 20 }, // Pickup Appointment
          { wch: 20 }, // Delivery ETA
          { wch: 20 }, // Delivery Time
          { wch: 25 }, // Reason Code
          { wch: 20 }, // Tracker
          { wch: 20 }, //Last Updated Time
          { wch: 20 }, // Pickup Time
          { wch: 25 }, //Notes
          { wch: 15 }, // Warehouse
          { wch: 25 }, // Customer Name
          { wch: 20 }, // Transport Mode
          { wch: 20 }, // Customer Region
          { wch: 25 }, // Original Request Date
          { wch: 15 }, // Customer ID
          { wch: 15 }, // Data Source
          { wch: 20 }, // Phone Number
          { wch: 20 }, // Commodity
          { wch: 15 }, // RescheduleDate
        ];

        worksheet["!cols"] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Shipments");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "shipments.xlsx");
      } else if (format === "csv") {
        const csvContent =
          "data:text/csv;charset=utf-8," +
          XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(formattedData));
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "shipments.csv");
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setIsExportDisabled(false); // Re-enable export button
    }
  };

  return (
    <Box sx={{ padding: "0px", margin: "0px" }}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "0px",
          paddingBottom: 0,
        }}
      >
        <Search
          onApplyFilters={handleApplyFilters}
          onExportButtonClick={handleExportButtonClick}
          exportPopupOpen={exportPopupOpen}
          onExportPopupClose={handleExportPopupClose}
          selectedDate={appliedFilters?.SelectedDate}
          appliedFiltersCount={appliedFiltersCount}
          appliedFilters={appliedFilters}
          isExportDisabled={isExportDisabled}
          accountInfo={accountInfo} 
        />
        {isCustomerUser ?
          (<CustomerShipmentTable
            appliedFilters={appliedFilters}
            onRowsChange={handleRowsChange}
            accountInfo={accountInfo}
            onFetchError={handleFetchError}
          />) :
          (<ShipmentTable
            appliedFilters={appliedFilters}
            onRowsChange={handleRowsChange}
            accountInfo={accountInfo}
            onFetchError={handleFetchError}
            setIsSaveBtnClicked={setIsSaveBtnClicked}
          />)
        }
      </Box>
      <Metrics
        selectedDate={appliedFilters?.SelectedDate}
        CustomerName={appliedFilters?.CustomerName || ''}
        CustomerRegion={appliedFilters?.CustomerRegion || ''}
        DeliveryStatus={appliedFilters?.DeliveryStatus || ''}
        City={appliedFilters?.City || ''}
        isSaveBtnClicked={isSaveBtnClicked}
        setIsSaveBtnClicked={setIsSaveBtnClicked}
      />
      <AddCustomerPopup open={open} setOpen={setOpen} />
    </Box>
  );
};

export default DashboardOverview;
