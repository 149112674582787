/**
 * @description {Method to parse JSON}
 * @param {String} data
 * @returns {Object || Null}
 */
const parseJSON = (data) => {
	try {
		const result = JSON.parse(data);
		return result;
	} catch (err) {
		return null;
	}
};

/**
 * @description {Method to get data from localstorage}
 * @param {String} key
 * @returns
 */
export const getItem = (key) => localStorage.getItem(key);

/**
 * @description {Method to save data in localstorage}
 * @param {String} key
 * @param {Any} value
 * @returns
 */
export const setItem = (key, value) => localStorage.setItem(key, value);

/**
 * @description {Method to rmeove data from local storage}
 * @param {String} key
 * @returns
 */
export const removeItem = (key) => localStorage.removeItem(key);

/**
 * @description {Method to clear localstorage}
 * @returns {Void}
 */
export const clearAll = () => localStorage.clear();

/**
 * @description {Method to get parsed data from localstorage}
 * @param {String} key
 * @returns
 */
export const getParsedItem = (key) => parseJSON(getItem(key));

/**
 * @description {Method to save object inside localstorae}
 * @param {String} key
 * @param {Object} value
 * @returns {Void}
 */
export const setStringfyItem = (key, value) => setItem(key, JSON.stringify(value));
