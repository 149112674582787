import axiosInstance from "./axios";
import config from "./config";

export const getUsers = async () => {
    try {
      const response = await axiosInstance.get(config.endpoints.users);
      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };