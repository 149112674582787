import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const AlertMessage = ({
  open,
  handleClose,
  message, // 'success' | 'error' | 'warning' | 'info'
  severity = "info",
  autoHideDuration = 6000,
  customColor,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant='filled'
        sx={{ width: "35vw", backgroundColor: customColor }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
