import axiosInstance from './axios';
import config from './config';

export const fetchMetrics = async (selectedDate, CustomerName, CustomerRegion, DeliveryStatus, City) => {
  try {
    const response = await axiosInstance.get(
      `${config.endpoints.metrics_url}?SelectedDate=${selectedDate}&CustomerName=${CustomerName}&CustomerRegion=${CustomerRegion}&DeliveryStatus=${DeliveryStatus}&City=${City}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching metrics:', error);
    throw error;
  }
};
