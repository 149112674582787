import React, { createContext, useContext, useState, useCallback } from "react";
import AlertMessage from "../components/Reusables/AlertMessage";

export const toastContext = createContext(null);
export const useToastContext = () => useContext(toastContext);

const defaultToastTimeout = 2500;

const ToastContainer = ({ children }) => {
  const [toastState, setToastState] = useState({
    isActive: false,
    severity: "info",
    message: "",
    timeout: defaultToastTimeout,
  });

  const severityColors = {
    success: "#339900",
    error: "#FF0000",
    warning: "#ff9800",
    info: "#2196f3",
  };

  const showToast = useCallback((severity, message, timeout = defaultToastTimeout) => {
    setToastState({ isActive: true, severity, message, timeout });
    setTimeout(() => {
      setToastState((prevState) => ({ ...prevState, isActive: false }));
    }, timeout);
  }, []); // Use an empty dependency array

  const showSuccessToast = useCallback((message) => showToast("success", message), [showToast]);
  const showErrorToast = useCallback((message) => showToast("error", message), [showToast]);
  const showWarningToast = useCallback((message) => showToast("warning", message), [showToast]);
  const showInfoToast = useCallback((message) => showToast("info", message), [showToast]);

  return (
    <toastContext.Provider
      value={{ showSuccessToast, showErrorToast, showWarningToast, showInfoToast }}
    >
      <AlertMessage
        open={toastState.isActive}
        handleClose={() => setToastState((prevState) => ({ ...prevState, isActive: false }))}
        message={toastState.message}
        severity={toastState.severity}
        autoHideDuration={toastState.timeout}
        customColor={severityColors[toastState.severity]} // Pass the custom color
      />
      {children}
    </toastContext.Provider>
  );
};

export default ToastContainer;
