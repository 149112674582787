import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fetchCustomers } from "../../api/customers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { fetchCustomerRegions } from "../../api/customerRegions";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { getCachedisCustomerUser, getCachedCustomerName } from "../../api/shipmentTable";

const Sidebar = ({ isOpen, toggleDrawer, onApplyFilters, initialDate, accountInfo }) => {
  const theme = useTheme();
  const isCustomerUser = getCachedisCustomerUser();
  const customerName = localStorage.getItem("customerName");

  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedCustomerName, setSelectedCustomerName] = useState(
    isCustomerUser ? customerName : null
  );
  const [customerRegions, setCustomerRegions] = useState([]);
  const [selectedCustomerRegions, setSelectedCustomerRegions] = useState([]);
  const [loadingRegions, setLoadingRegions] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs(initialDate));
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState(null);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [dateError, setDateError] = useState(false);
  const [city, setCity] = useState("");
  const [availableDates, setAvailableDates] = useState([]);

  // Update available dates based on current time
  useEffect(() => {
    const updateAvailableDates = () => {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      const dates = [];

      const now = dayjs(currentDate);

      dates.push(now);

      if (currentHour >= 16) {
        dates.push(now.add(1, "day"));
        for (let i = 1; i <= 3; i++) {
          dates.push(now.subtract(i, "day"));
        }
      } else {
        for (let i = 1; i <= 4; i++) {
          dates.push(now.subtract(i, "day"));
        }
      }

      setAvailableDates(dates);
    };

    updateAvailableDates();
    const interval = setInterval(updateAvailableDates, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const initializeCustomerUser = async () => {
      if (isCustomerUser && customerName) {
        try {
          const customersData = await fetchCustomers();
          const customerRecord = customersData.find(
            (customer) => customer.name.toLowerCase() === customerName.toLowerCase()
          );

          if (customerRecord) {
            setSelectedCustomerId(customerRecord.id);
            setSelectedCustomerName(customerName);

            setLoadingRegions(true);
            try {
              const regions = await fetchCustomerRegions(customerRecord.id);
              setCustomerRegions(Array.isArray(regions) ? regions : []);
            } catch (error) {
              console.error("Error fetching regions for customer user:", error);
              setCustomerRegions([]);
            } finally {
              setLoadingRegions(false);
            }
          }
        } catch (error) {
          console.error("Error fetching customer data:", error);
        }
      }
    };

    initializeCustomerUser();
  }, [isCustomerUser, customerName]);

  useEffect(() => {
    const loadCustomers = async () => {
      if (!isCustomerUser) {
        try {
          const data = await fetchCustomers();
          setCustomers(data);
        } catch (error) {
          console.error("Error fetching customers:", error);
          setCustomers([]);
        }
      }
    };

    loadCustomers();
  }, [isCustomerUser]);

  useEffect(() => {
    setSelectedDate(dayjs(initialDate));
  }, [initialDate]);

  useEffect(() => {
    const calculateAppliedFiltersCount = () => {
      let count = 0;
      if (selectedDeliveryStatus) count++;
      if (selectedDate) count++;
      if (selectedCustomerId && !isCustomerUser) count++;
      if (selectedCustomerRegions.length > 0) count++;
      if (city) count++;
      setAppliedFiltersCount(count);
    };

    calculateAppliedFiltersCount();
  }, [
    selectedDeliveryStatus,
    selectedDate,
    selectedCustomerId,
    selectedCustomerRegions,
    city,
    isCustomerUser,
  ]);

  const handleCustomerChange = async (customerId, customerName) => {
    if (isCustomerUser) return;

    setLoadingRegions(true);

    if (selectedCustomerId === customerId) {
      setSelectedCustomerId(null);
      setSelectedCustomerName(null);
      setSelectedCustomerRegions([]);
      setCustomerRegions([]);
    } else {
      try {
        setSelectedCustomerId(customerId);
        setSelectedCustomerName(customerName);
        setSelectedCustomerRegions([]);

        const regions = await fetchCustomerRegions(customerId);
        setCustomerRegions(Array.isArray(regions) ? regions : []);
      } catch (error) {
        console.error("Error fetching regions:", error);
        setCustomerRegions([]);
      }
    }
    setLoadingRegions(false);
  };

  const handleCustomerRegionChange = (region) => {
    const index = selectedCustomerRegions.indexOf(region);
    if (index === -1) {
      setSelectedCustomerRegions([...selectedCustomerRegions, region]);
    } else {
      setSelectedCustomerRegions(selectedCustomerRegions.filter((r) => r !== region));
    }
  };

  const handleDeliveryStatusChange = (status) => {
    if (selectedDeliveryStatus === status) {
      setSelectedDeliveryStatus(null);
    } else {
      setSelectedDeliveryStatus(status);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateError(false);
  };

  const handleCityChange = (event) => {
    const value = event.target.value;
    if (value.startsWith(" ")) {
      return;
    }
    setCity(value);
  };

  const handleClearFilters = () => {
    const currentDate = new Date();
    setSelectedDeliveryStatus(null);
    setSelectedDate(dayjs(currentDate));
    setSelectedCustomerRegions([]);
    setDateError(false);
    setCity("");

    if (!isCustomerUser) {
      setSelectedCustomerId(null);
      setSelectedCustomerName(null);
    }

    const filters = {
      SelectedDate: moment(currentDate).format("YYYYMMDD"),
      CustomerName: isCustomerUser ? customerName : null,
      CustomerRegion: null,
      DeliveryStatus: null,
      City: null,
      AppliedFiltersCount: 1,
      isCustomerUser: isCustomerUser,
    };
    onApplyFilters(filters, true);
    toggleDrawer();
  };

  const handleApplyFilters = () => {
    const isValidDate = availableDates.some(
      (availableDate) => selectedDate.format("YYYY-MM-DD") === availableDate.format("YYYY-MM-DD")
    );

    if (!isValidDate) {
      setDateError(true);
      return;
    }

    const filters = {
      SelectedDate: selectedDate ? selectedDate.format("YYYYMMDD") : null,
      CustomerName: isCustomerUser ? customerName : selectedCustomerName,
      CustomerRegion: selectedCustomerRegions.length > 0 ? selectedCustomerRegions.join(",") : null,
      DeliveryStatus: selectedDeliveryStatus || null,
      City: city || null,
      AppliedFiltersCount: appliedFiltersCount,
      isCustomerUser: isCustomerUser,
    };
    onApplyFilters(filters);
    toggleDrawer();
  };

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={toggleDrawer}
      PaperProps={{
        sx: {
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Box
        sx={{
          width: 350,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "28px",
              fontWeight: 400,
              lineHeight: "36px",
              letterSpacing: "0px",
              textAlign: "left",
              margin: "20px",
            }}
            variant='h6'
            gutterBottom
          >
            Filters
          </Typography>

          {!isCustomerUser && (
            <Accordion
              sx={{
                border: "1px solid #E8F2FF",
                "&.Mui-expanded": {
                  margin: 0,
                },
              }}
            >
              <AccordionSummary
                sx={{ height: "50px" }}
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary }} />}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: selectedCustomerId ? "bold" : "normal",
                  }}
                >
                  Customer
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 0,
                  "& .MuiFormControlLabel-root": {
                    margin: 0,
                  },
                }}
              >
                {customers && customers.length > 0 ? (
                  customers.map((customer) => (
                    <FormControlLabel
                      key={customer.id}
                      control={
                        <Checkbox
                          sx={{
                            color: "#006098",
                            "&.Mui-checked": { color: "#006098" },
                          }}
                          checked={selectedCustomerId === customer.id}
                          onChange={() => handleCustomerChange(customer.id, customer.name)}
                        />
                      }
                      label={
                        <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                          {customer.name}
                        </Typography>
                      }
                      sx={{ borderBottom: "1px solid #E8F2FF" }}
                    />
                  ))
                ) : (
                  <Typography sx={{ fontFamily: "Poppins, sans-serif", p: 2 }}>
                    No customers available
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )}

          <Accordion
            sx={{
              border: "1px solid #E8F2FF",
              "&.Mui-expanded": {
                margin: 0,
              },
            }}
            disabled={loadingRegions || (!selectedCustomerId && !isCustomerUser)}
          >
            <AccordionSummary
              sx={{ height: "50px" }}
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary }} />}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: selectedCustomerRegions.length > 0 ? "bold" : "normal",
                }}
              >
                Region
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 0,
                "& .MuiFormControlLabel-root": {
                  margin: 0,
                },
              }}
            >
              {loadingRegions ? (
                <Typography sx={{ fontFamily: "Poppins, sans-serif", p: 2 }}>
                  Loading regions...
                </Typography>
              ) : customerRegions && customerRegions.length > 0 ? (
                customerRegions.map((region) => (
                  <FormControlLabel
                    key={region.id}
                    control={
                      <Checkbox
                        sx={{
                          color: "#006098",
                          "&.Mui-checked": { color: "#006098" },
                        }}
                        checked={selectedCustomerRegions.includes(region.region)}
                        onChange={() => handleCustomerRegionChange(region.region)}
                      />
                    }
                    label={
                      <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                        {region.region}
                      </Typography>
                    }
                    sx={{ borderBottom: "1px solid #E8F2FF" }}
                  />
                ))
              ) : (
                <Typography sx={{ fontFamily: "Poppins, sans-serif", p: 2 }}>
                  No regions available
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          {isCustomerUser && (
            <Box
              sx={{
                border: "1px solid #E8F2FF",
                padding: "16px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: city ? "bold" : "normal",
                  marginBottom: "8px",
                }}
              >
                Enter City
              </Typography>
              <TextField
                fullWidth
                type='text'
                placeholder='City'
                value={city}
                onChange={handleCityChange}
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  color: theme.palette.text.primary,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.palette.border.main,
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.border.main,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.FocusStyle.main,
                    },
                    "& fieldset": {
                      border: theme.palette.border.main,
                    },
                  },
                }}
              />
            </Box>
          )}

          <Accordion
            sx={{
              border: "1px solid #E8F2FF",
              marginBottom: "10px",
              "&.Mui-expanded": {
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              sx={{ height: "50px" }}
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary }} />}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: selectedDeliveryStatus ? "bold" : "normal",
                }}
              >
                Delivery Status
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 0,
                "& .MuiFormControlLabel-root": {
                  margin: 0,
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#006098",
                      "&.Mui-checked": { color: "#006098" },
                    }}
                    checked={selectedDeliveryStatus === "Intransit"}
                    onChange={() => handleDeliveryStatusChange("Intransit")}
                  />
                }
                label={
                  <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>In Transit</Typography>
                }
                sx={{ borderBottom: "1px solid #E8F2FF" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#006098",
                      "&.Mui-checked": { color: "#006098" },
                    }}
                    checked={selectedDeliveryStatus === "Pending Pickup"}
                    onChange={() => handleDeliveryStatusChange("Pending Pickup")}
                  />
                }
                label={
                  <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>Pending Pickup</Typography>
                }
                sx={{ borderBottom: "1px solid #E8F2FF" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#006098",
                      "&.Mui-checked": { color: "#006098" },
                    }}
                    checked={selectedDeliveryStatus === "On Time"}
                    onChange={() => handleDeliveryStatusChange("On Time")}
                  />
                }
                label={<Typography sx={{ fontFamily: "Poppins, sans-serif" }}>On Time</Typography>}
                sx={{ borderBottom: "1px solid #E8F2FF" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#006098",
                      "&.Mui-checked": { color: "#006098" },
                    }}
                    checked={selectedDeliveryStatus === "Late"}
                    onChange={() => handleDeliveryStatusChange("Late")}
                  />
                }
                label={<Typography sx={{ fontFamily: "Poppins, sans-serif" }}>Late</Typography>}
                sx={{ borderBottom: "1px solid #E8F2FF" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#006098",
                      "&.Mui-checked": { color: "#006098" },
                    }}
                    checked={selectedDeliveryStatus === "Missed"}
                    onChange={() => handleDeliveryStatusChange("Missed")}
                  />
                }
                label={<Typography sx={{ fontFamily: "Poppins, sans-serif" }}>Missed</Typography>}
              />
            </AccordionDetails>
          </Accordion>
          <Box
            sx={{
              paddingLeft: "20px",
              paddingRight: "20px",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: selectedDate ? "bold" : "normal",
              }}
            >
              Select Delivery Appointment
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label='Select a date'
                  value={selectedDate}
                  onChange={handleDateChange}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.palette.border.main,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.border.main,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.FocusStyle.main,
                      },
                    },
                  }}
                  shouldDisableDate={(date) => {
                    return !availableDates.some(
                      (availableDate) =>
                        dayjs(date).format("YYYY-MM-DD") === availableDate.format("YYYY-MM-DD")
                    );
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: dateError,
                      helperText: dateError
                        ? "Please select a valid date from the available options"
                        : "",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Button
            variant='text'
            sx={{
              textTransform: "none",
              color: theme.palette.text.primary,
              marginRight: "16px",
              fontFamily: "Poppins, sans-serif",
            }}
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
          <Button
            variant='text'
            sx={{
              textTransform: "none",
              color: theme.palette.text.primary,
              marginRight: "16px",
              fontFamily: "Poppins, sans-serif",
            }}
            onClick={toggleDrawer}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{
              textTransform: "none",
              borderRadius: "80px",
              bgcolor: theme.palette.button.main,
              "&:hover": { bgcolor: theme.palette.buttonhover.main },
              fontFamily: "Poppins, sans-serif",
            }}
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
