import React from "react";
import ToastContainer from "./toast";

const contextProviders = [ToastContainer];

const AppContextProvider = ({ children }) => {
  return contextProviders.reduceRight(
    (child, ContextProvider) => <ContextProvider>{child}</ContextProvider>,
    children
  );
};

export default AppContextProvider;
