import React, { useState } from "react";
import { Tabs, Tab, Box, useTheme } from "@mui/material";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: "0px", margin: "0px" }}>{children}</Box>}
    </div>
  );
};

const ReusableTabs = ({ tabs, defaultIndex = 0 }) => {
  const [value, setValue] = useState(defaultIndex);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", padding: "0px", margin: "0px" }}>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs
          sx={{
            padding: "20px",
            margin: "0px",
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.FocusStyle.main,
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          {tabs.map((tab, index) => (
            <Tab
              sx={{
                padding: "0px",
                margin: "0px",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 400,
                color: value === index ? theme.palette.FocusStyle.main : theme.palette.tabbtn.main,
                "&.Mui-selected": {
                  color: theme.palette.FocusStyle.main,
                  fontWeight: "bold",
                },
              }}
              key={index}
              label={tab.label}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel sx={{ padding: "0px", margin: "0px" }} key={index} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default ReusableTabs;
