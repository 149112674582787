import axiosInstance from "./axios";
import config from "./config";

export const addUser = async (payload) => {
  try {
    const response = await axiosInstance.post(config.endpoints.users, payload);
    return response.data;
  } catch (error) {
    console.error("Error adding new user", error);
    throw error;
  }
};
